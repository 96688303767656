import React from "react";
import {FiEdit, FiTrash2} from 'react-icons/fi'

import {
  Content,
  IdUser,
  NameUser,
  // CargoUser,
  OptionsUser,
  Icons,
  UnitsUser,
  Units
} from './styles'
import { Item, RowInfo } from "../../pages/Admin/styles";

export const getComponentUnitsTable = (units, allUnits) => {
  let content;
  if (!Array.isArray(units) || !Array.isArray(allUnits)) return
  if (units.length !== 0) {
    content = units.length === allUnits.length
      ? "TODAS as unidades"
      : units.map((u) => u.unidade).join(", ");

    if (content.length > 110) { 
      content = content.substring(0, 110) + '...';
    }
  }
  if (typeof content !== 'string') content = "NENHUMA unidade"
  return (
    <RowInfo>
      <Item>
        {content}
      </Item>
    </RowInfo>
  );
}
export default function User({
  dataUser,
  handleCliskEdit,
  handleDelete,
}){
  return(
    <Content>
      <NameUser>{dataUser.nome}</NameUser>
      <Units>
        {dataUser.unidades.map(u => u.unidade).join(', ')}
      </Units>
      {/* <CargoUser>{dataUser.perfil.perfil}</CargoUser> */}
      <OptionsUser>
        <Icons setColor={'#FFDE59'} onClick={() => handleCliskEdit()}><FiEdit color="#fff" /></Icons>  
        <Icons setColor={'#E23636'} onClick={handleDelete}><FiTrash2 color="#fff" /></Icons>
      </OptionsUser>
    </Content>
  )
}